@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.fonts-300 {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}
.fonts-400 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}
.fonts-500 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
.fonts-600 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}
body{
    line-height: none !important;
}
.section {
    border: none; 
  }
 
.react-tel-input .form-control{
    width:100% !important;
    height:44px !important;
    background-color: #404040 !important;
    color: #fff  !important;
    border-color: #A5A5A5 !important;
    border-radius: 12px !important;
    
}
.react-tel-input .form-control ::placeholder{
    color: #6b6b6b  !important;
}
.react-tel-input .flag-dropdown{
    height: 44px !important;
    background-color: #404040 !important;
    border-radius: 12px 0 0  12px!important;
    border-right: none !important;
    border-color: #A5A5A5 !important;
   
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #3d3d3d;
    border-radius: 12px !important;
    border-right: none !important;
    border-color: #A5A5A5 !important;
    color:white !important;
}
.react-tel-input .flag-dropdown.selected-flag:hover{
    background-color: #3d3d3d;
    border-radius: 12px !important;
    border-right: none !important;
    border-color: #A5A5A5 !important;
    color:white !important;
}
.react-tel-input .country-list{
    background-color: #3d3d3d !important;
    color: white !important;
}
.react-tel-input .country-list .country.highlight{
    background-color: white  !important;
    color: #3d3d3d ;
    border-right: none !important;
    border-color: #A5A5A5 !important;
    
}
.react-tel-input .country-list .country:hover{
    background-color: #c9c9c9 !important;
    color: #000 !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background-color: #3d3d3d !important; 
    border-radius: 12px 0 0  12px!important;
    border-right: none !important;
    border-color: #A5A5A5 !important;

}
.react-tel-input .flag-dropdown.open .selected-flag{
    background-color: #3d3d3d !important;
}
::placeholder{
    color: #A5A5A5 !important;
}
