@media only screen and (min-width: 1920px) {
    .containers {
        width: 1376px !important;
        margin: auto !important;
       
    }
    .slick-prev:before, .slick-next:before{
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 1920px) {
    .containers {
        width: 1376px !important;
        margin: auto !important;
    }
    .slick-prev:before, .slick-next:before{
        font-size: 22px !important;
    }
}

@media only screen and (max-width:1480px) {
    .containers {
        width: 1200px !important;
        margin: auto !important;
    }
}

@media only screen and (max-width:1280px) {
    .containers {
        margin: auto !important;
        width: 917px !important;
    }
   
}

@media only screen and (max-width:1080px) {
    .containers {
        margin: auto !important;
        width: 750px !important ;
    }
}

@media only screen and (max-width:875px) {
    .containers {
        width: 689px !important;
        margin: auto !important;
     }
  }
@media only screen and (max-width:800px) {
    .containers {
        width: 599px !important;
        margin: auto !important;
     }   
}
@media only screen and (max-width:600px) {
    .containers {
        width: 489px !important;
        margin: auto !important;
     }
   
    .slick-prev:before, .slick-next:before{
        font-size: 20px !important;
    }
    
}

@media only screen and (max-width:500px) {
    .containers {
        padding-left:30px !important;
        padding-right:30px !important;
        width: 100% !important;
    }
    .react-tel-input .form-control{
        height: 40px !important;
    }
    .react-tel-input .flag-dropdown{
        height: 40px !important;
    }
    .slick-dots li{
        margin: 0 !important;
    }

}